import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Soheil Shirvani</h2>
        <p><a href="mailto:shirvani.soheil@unb.a">shirvani.soheil@unb.ca</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Soheil. I like building things. I am a CS graduate student at University of
        New Brunswick and did my bachelors of Engineering degree at University of Tehran. <br />
        <b>I Am a Prospective PH.D. Student Looking for a Position in top rank US universities
          that align with my interests.
        </b>
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Soheil Shirvani</p>
    </section>
  </section>
);

export default SideBar;
